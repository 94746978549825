<main class="page page--timeline">
  <div class="clipboard">Copied!</div>
  <section class="map">
    <a *ngIf="moiMap" [routerLink]="[ '/' ]">
      <button moiButton class="back" [onMap]="true">
        <moi-svg [iconLeft]="true" icon="carret-left" fill="rgba(255,255,255, 1)" w="12" h="12"></moi-svg>
        <span>Back</span>
      </button>
    </a>
    <button moiButton *ngIf="moiMap" class="map__share" [onMap]="true" (click)="shareClick()">
      <moi-svg [iconLeft]="true" icon="share" fill="rgba(255,255,255, 0.5)" w="14" h="14"></moi-svg>
      <span>Share this moment</span>
    </button>
    <div class="map-zoom">
      <button moiButton [clean]="true" class="map-zoom__in">
        <moi-svg icon="plus" fill="rgba(255,255,255, .5)" w="12" h="12"></moi-svg>
      </button>
      <div class="map-zoom__divider"></div>
      <button moiButton class="map-zoom__out" [clean]="true">
        <moi-svg icon="minus" fill="rgba(255,255,255, .5)" w="12" h="12"></moi-svg>
      </button>
    </div>
    <div id="map" class="map__container"></div>
  </section>

  <div id="momentDialog" class="moment-tooltip" #momentDialog>
    <div *ngIf="selectedMoment" class="moment-tooltip__container">
      <moi-img class="moment-tooltip__image" *ngIf="selectedMoment.photos.length" [src]="selectedMoment.photos[0].url"
        (imageLoaded)="onImageLoad($event)" bgColor="#262525"></moi-img>
      <div class="moment-tooltip__body">
        <h2 class="moment-tooltip__title">{{ selectedMoment.title }}</h2>
        <h3 class="moment-tooltip__date">({{ selectedMoment.display_date }})</h3>
        <p class="moment-tooltip__description">{{ selectedMoment.description }}</p>
        <a class="moment-tooltip__link" target="_blank" href="{{ selectedMoment.wikipedia_link }}">Learn more</a>
      </div>
    </div>
  </div>

  <aside #sidebar class="sidebar" id="sidebar">
    <div class="adv adv--top adv--with-overlay" #advTop>
      <ng-container *ngIf="isMobile(); then thenTemplate; else elseTemplate"></ng-container>
      <ng-template #thenTemplate>
        <ins class="adsbygoogle 320"
            style="display:block"
            data-ad-client="ca-pub-4778528650661059"
            data-ad-slot="9380522578"></ins>
        <!-- <img class="adv__image" src="https://via.placeholder.com/320x100/4a4a4a/fff" alt="ad text"> -->
      </ng-template>
      <ng-template #elseTemplate>
        <ins class="adsbygoogle 325"
            style="display:block"
            data-ad-client="ca-pub-4778528650661059"
            data-ad-slot="9380522578"></ins>
        <!-- <img class="adv__image" src="https://via.placeholder.com/325x150/4a4a4a/fff" alt="ad text"> -->
      </ng-template>
    </div>
    <div class="timeline-player" *ngIf="timeline">
      <div class="timeline-player__next-prev">
        <button moiButton class="timeline-player__prev" [accent]='true' [round]='true' [disabled]="currentMomentIndex <= 0"
          (click)="showPreviousMoment()">
          <moi-svg icon='arrow-up' w='20' h='20'></moi-svg>
        </button>
        <button moiButton class="timeline-player__next" [accent]='true' [round]='true' [disabled]="currentMomentIndex >= timeline.length - 1"
          (click)="showNextMoment()">
          <moi-svg icon='arrow-down' w='20' h='20'></moi-svg>
        </button>
      </div>
      <div class="timeline-player__restart">
        <button moiButton [round]='true' [clean]="true" (click)="showFirstMoment()">
          <moi-svg icon='restart' w='20' h='20' fill="white"></moi-svg>
        </button>
      </div>
    </div>
    <div class="timeline-indicator">
      <div class="timeline__pointer timeline-indicator__pointer" id="timelinePointer"></div>
    </div>
    <div class="moment-scroller" #momentScroller *ngIf="moiMap">
      <div class="moment" (click)="onMomentClick(moment, $event)" [ngClass]="{'moment--is-active': selectedMoment && moment.title === selectedMoment.title}"
        #moments *ngFor="let moment of timeline; let i = index" [id]="'moment' + i">
        <!-- <p>{{ moment.id }}</p> -->
        <p class="moment__title">{{ moment.title }}</p>
        <p class="moment__date">{{ moment.display_date }}</p>
        <p class="moment__description">{{ moment.description }}</p>
        <a class="moment__link" target="_blank" href="{{ moment.wikipedia_link }}">
          <moi-svg class="moment__link-icon" [iconLeft]="true" icon="link" fill="#88ed9e" w="10" h="10"></moi-svg>
          Learn more
        </a>
      </div>
    </div>
  </aside>

</main>
