/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./topbar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./topbar.component";
var styles_TopbarComponent = [i0.styles];
var RenderType_TopbarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TopbarComponent, data: {} });
export { RenderType_TopbarComponent as RenderType_TopbarComponent };
export function View_TopbarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "topbar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "topbar__moments"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Moments of Interest "])), (_l()(), i1.ɵeld(3, 0, null, null, 3, "a", [["class", "topbar__logo"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(5, 1), (_l()(), i1.ɵted(-1, null, ["MOI"])), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "topbar__support-us"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 3, "a", [["class", "support-link"], ["href", "#"], ["rel", "nofollow noreferrer"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [["class", "underlined"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Support"])), (_l()(), i1.ɵted(-1, null, [" our history platform"]))], function (_ck, _v) { var currVal_2 = _ck(_v, 5, 0, "/"); _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).target; var currVal_1 = i1.ɵnov(_v, 4).href; _ck(_v, 3, 0, currVal_0, currVal_1); }); }
export function View_TopbarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-topbar", [], null, null, null, View_TopbarComponent_0, RenderType_TopbarComponent)), i1.ɵdid(1, 114688, null, 0, i4.TopbarComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TopbarComponentNgFactory = i1.ɵccf("app-topbar", i4.TopbarComponent, View_TopbarComponent_Host_0, {}, {}, []);
export { TopbarComponentNgFactory as TopbarComponentNgFactory };
