import { Component, OnInit, Input, HostBinding, ViewChild, ElementRef } from "@angular/core";

@Component({
  selector: "moi-img",
  styleUrls: ["./img.component.scss"],
  host: {
    class: "moi-image-container",
    "[class.loading]": "loading",
    "[style.width]": "w",
    "[style.height]": "h"
  },
  template: `
    <img 
      [src]="src"
      alt="{{ alt }}"
      [ngStyle]="{'width': w, 'height': h }"
      class="moi-image" 
      (load)="onImageLoad()"
      [ngClass]="{'moi-image--is-loading': loading}"
      #img 
    >
  `
})

export class ImgComponent implements OnInit {
  @Input("class")
  class: string = "";

  @HostBinding("style.background")
  @Input()
  bgColor: string = "linear-gradient( 135deg, #81FBB8 10%, #28C76F 100%)";

  @Input()
  w: string;
  @Input()
  h: string;

  @Input()
  src: string;
  @Input()
  alt: string;

  @ViewChild("img")
  img: ElementRef;

  loading: boolean = true;

  constructor() { }

  ngOnInit() { }

  ngOnChanges(changes: any): void {
    if (changes.src.currentValue !== changes.src.previousValue) {
      this.loading = true;
    }
  }

  onImageLoad() {
    this.loading = false;
  }
}
