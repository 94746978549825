/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./svg.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./svg";
var styles_MoiSvg = [i0.styles];
var RenderType_MoiSvg = i1.ɵcrt({ encapsulation: 2, styles: styles_MoiSvg, data: {} });
export { RenderType_MoiSvg as RenderType_MoiSvg };
export function View_MoiSvg_0(_l) { return i1.ɵvid(0, [i1.ɵncd(null, 0)], null, null); }
export function View_MoiSvg_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "moi-svg", [], null, null, null, View_MoiSvg_0, RenderType_MoiSvg)), i1.ɵdid(1, 114688, null, 0, i2.MoiSvg, [i1.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MoiSvgNgFactory = i1.ɵccf("moi-svg", i2.MoiSvg, View_MoiSvg_Host_0, { icon: "icon", w: "w", h: "h", fill: "fill", class: "class", iconLeft: "iconLeft", iconRight: "iconRight" }, {}, ["*"]);
export { MoiSvgNgFactory as MoiSvgNgFactory };
