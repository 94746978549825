import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./pages/home/home.component";
import { TimelineComponent } from "./pages/timeline/timeline.component";

// import { DevComponents } from "./dev/components";

const routes: Routes = [
  {
    path: "",
    component: HomeComponent
  },
  {
    path: "timeline/:id/:slug",
    component: TimelineComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
