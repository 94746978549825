<header>
  <app-topbar></app-topbar>
</header>
<div class="home-background-img"></div>
<main class="page page--home">
  <div class="page__header">
    <h1 class="page__title">
      Zoom in space-time
    </h1>
    <button moiButton [accent]="true">About MOI <moi-svg [iconRight]="true" icon="arrow-right" h="14" w="14" fill="white"></moi-svg></button>
  </div>
  <!-- <section class="section section--slider">
    <h2 class="section__title">
      Feature Maps
    </h2>
    <div class="cards-container glide">
      <div data-glide-el="track" class="glide__track">
        <ul class="glide__slides">
          <li class="glide__slide" *ngFor="let map of featureMaps">
            <app-card [map]="map" #mapCards></app-card>
          </li>
        </ul>
      </div>
       <div class="glide">
        <div class="glide__arrows" data-glide-el="controls">
          <button class="glide__arrow glide__arrow--left" data-glide-dir="<">prev</button>
          <button class="glide__arrow glide__arrow--right" data-glide-dir=">">next</button>
        </div>
      </div>
  </div>
  </section> -->

  <section class="section section--tilt">
    <h2 class="section__title">
      Featured Maps
    </h2>
    <div class="cards-container tilt">
      <moi-card [withTilt]="true" [map]="map" #mapCards *ngFor="let map of maps" class="homepage-card"></moi-card>
    </div>
  </section>

  <section class="section section--map-categories">
    <h2 class="section__title">
      Map categories coming soon
    </h2>
    <div class="map-categories-container">
      <div class="map-category">
        <img src="assets/icons/history.svg" alt="Moments in History" class="map-category__icon">
        <h3 class="map-category__title">
          History
        </h3>
      </div>
      <div class="map-category">
        <img src="assets/icons/athletics.svg" alt="Moments in Athleticism" class="map-category__icon">
        <h3 class="map-category__title">
          Athletics
        </h3>
      </div>
      <div class="map-category">
        <img src="assets/icons/science.svg" alt="Moments in Science" class="map-category__icon">
        <h3 class="map-category__title">
          Science
        </h3>
      </div>
      <div class="map-category">
        <img src="assets/icons/architecture.svg" alt="Moments in Architecture" class="map-category__icon">
        <h3 class="map-category__title">
          Architecture
        </h3>
      </div>
      <div class="map-category">
        <img src="assets/icons/nature.svg" alt="Moments in Nature" class="map-category__icon">
        <h3 class="map-category__title">
          Nature
        </h3>
      </div>
      <div class="map-category">
        <img src="assets/icons/culture.svg" alt="Moments in Culture" class="map-category__icon">
        <h3 class="map-category__title">
          Culture
        </h3>
      </div>
    </div>
  </section>
  <section class="section section--support-us">
    <div class="support-us">
      <h2 class="support-us__title">
        More amazing features coming soon
      </h2>
      <button moiButton [accent]="true">
        Help us make it happen
      </button>
    </div>
  </section>
  <!-- <moi-footer></moi-footer> -->
</main>