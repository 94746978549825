import { OnInit, QueryList, ElementRef } from "@angular/core";
import { DataService } from "../../services/data.service";
// import Glide from "@glidejs/glide";
import VanillaTilt from "vanilla-tilt";
import { FirebaseService } from "../../services/firebase.service";
var HomeComponent = /** @class */ (function () {
    function HomeComponent(data, firebaseService) {
        this.data = data;
        this.firebaseService = firebaseService;
        this.slideWidth = 260;
        this.maps = null;
        // calculateSliderSettings() {
        //   this.slideTrack = document.querySelector(".glide__track").getBoundingClientRect().width;
        //   this.perView = Math.floor(this.slideTrack / this.slideWidth);
        //   this.peekAfter = this.slideTrack - this.perView * (this.slideWidth + 8);
        // }
        // initializeSlider() {
        //   const slider = new Glide(".glide", {
        //     type: "slider",
        //     startAt: 0,
        //     bound: true,
        //     perView: this.perView,
        //     gap: 8,
        //     peek: {
        //       before: 0,
        //       after: this.peekAfter
        //     }
        //   });
        // slider.mount();
        // }
        this.isMobile = function () { return window.innerWidth <= 768; };
    }
    Object.defineProperty(HomeComponent.prototype, "mapCards", {
        set: function (mapCards) {
            if (this.isMobile()) {
                return;
            }
            this.initTilt();
        },
        enumerable: true,
        configurable: true
    });
    HomeComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._mapsSubscription = this.data.maps.subscribe(function (res) {
            _this.maps = res;
        });
        this.firebaseService.getMaps();
        window.addEventListener("resize", function () { return _this.initDestroyTilt(); });
    };
    HomeComponent.prototype.ngOnDestroy = function () {
        this._mapsSubscription.unsubscribe();
        window.removeEventListener("resize", this.initDestroyTilt);
    };
    HomeComponent.prototype.initDestroyTilt = function () {
        if (this.isMobile()) {
            this.destroyTilt();
        }
        else {
            this.initTilt();
        }
    };
    HomeComponent.prototype.initTilt = function () {
        var cards = document.querySelectorAll(".card.card--with-tilt");
        cards.forEach(function (card) {
            VanillaTilt.init(card, {
                max: 10,
                speed: 400,
                perspective: 1000,
                reverse: true,
                transition: true
            });
        });
    };
    HomeComponent.prototype.destroyTilt = function () {
        var cards = document.querySelectorAll(".card.card--with-tilt");
        cards.forEach(function (card) {
            if (card.vanillaTilt) {
                card.vanillaTilt.destroy();
            }
        });
    };
    return HomeComponent;
}());
export { HomeComponent };
