import { Injectable } from '@angular/core';
import { DataService, Map } from './data.service';
import * as firebase from "firebase/app";
import "firebase/firestore";

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  base: any;

  constructor(private data: DataService) {
    this.initFirebase();
  }

  db: any;

  initFirebase() {
    if (firebase.apps.length) return
    const config = {
      // apiKey: "AIzaSyC77IMSzncR28nKqg4uZaeZGSO4ClzX_Ps",
      // authDomain: "holy-kalo.firebaseapp.com",
      // databaseURL: "https://holy-kalo.firebaseio.com",
      projectId: "holy-moi"
      // storageBucket: "holy-kalo.appspot.com",
      // messagingSenderId: "90507705263"
    };
    firebase.initializeApp(config);
    this.db = firebase.firestore();
    const settings = { timestampsInSnapshots: true };
    this.db.settings(settings);
  };

  getMaps(): void {
    this.db &&
      this.db
        .collection("maps")
        .get()
        .then(
          querySnapshot => {
            const list = []
            querySnapshot.forEach(element => {
              const data = element.data();
              list.push(data);
            });
            this.data.maps.next(list);
          }
        );
  };

  getMap(map_id: string | number): void {
    this.db &&
      this.db
        .collection("maps")
        .where("id", "==", map_id)
        .get()
        .then(
          querySnapshot => {
            querySnapshot.forEach(element => {
              const data = element.data();
              this.data.map.next(data);
            });
          }
        );
  };

  getTimeline(map_id: string | number): void {
    this.db &&
      this.db
        .collection("timelines")
        .where("map_id", "array-contains", map_id)
        .get()
        .then(
          querySnapshot => {
            const timeline = []
            querySnapshot.forEach(element => {
              const data = element.data();
              timeline.push(data);
            });
            timeline.sort((a: any, b: any): any => {
              return this.formatDateIncludingBC(a.start_date).getFullYear() - this.formatDateIncludingBC(b.start_date).getFullYear()
            })
            this.data.timeline.next(timeline);
          }
        );
  }

  formatDateIncludingBC(date: string) {
    if (!date) {
      throw new Error("formatDateIncludingBC error. No date provided.");
    };
    const dateFragments: Array<any> = date.split('-');
    if (dateFragments[0].indexOf('−') === 0 || dateFragments[0].indexOf('-') === 0) {
      const d = new Date();
      const year = Number(dateFragments[0].slice(1));
      d.setFullYear(year * -1);
      return d;
    } else {
      // console.log(new Date(date))
      return new Date(date);
    };
  };
}
