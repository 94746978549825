import { Component, Input, ElementRef, OnInit, ViewEncapsulation } from "@angular/core";
import { icons } from "./icons";

@Component({
  selector: "moi-svg",
  template: "<ng-content></ng-content>",
  styleUrls: ["./svg.scss"],
  encapsulation: ViewEncapsulation.None
})
export class MoiSvg implements OnInit {
  @Input()
  icon: string;
  @Input()
  w: number = 24;
  @Input()
  h: number = 24;
  @Input()
  fill: string; // we can't have a default color because we use multicolored svgs
  @Input()
  class: string = "";
  @Input()
  iconLeft: boolean;
  @Input()
  iconRight: boolean;

  constructor(private element: ElementRef) {}

  ngOnInit(): void {
    this.element.nativeElement.innerHTML = `
    <svg xmlns="http://www.w3.org/2000/svg" class="moi-svg ${this.class ? this.class : ""} ${this.iconLeft ? "moi-svg--left-icon" : ""} ${
      this.iconRight ? "moi-svg--right-icon" : ""
    }" height="${this.h}" width="${this.w}" fill="${this.fill}" viewbox="0 0 512 512">
      ${icons[this.icon]}
    </svg>
    `;
  }
}
