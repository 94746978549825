import { Directive, Input, ElementRef } from "@angular/core";

@Directive({
  selector: "[moiButton]",
  host: {
    class: "btn",
    "[class.btn--accent]": "accent",
    "[class.btn--round]": "round",
    "[class.btn--clean]": "clean",
    "[class.btn--map]": "onMap",
    "[disabled]": "disabled"
  }
})
export class MoiButtonDirective {
  @Input()
  type: string;
  @Input()
  accent: boolean = false;
  @Input()
  round: boolean;
  @Input()
  disabled: boolean = false;
  @Input()
  clean: boolean;
  @Input()
  onMap: boolean;

  constructor(el: ElementRef) {
    // console.log(el);
  }
}
