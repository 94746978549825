/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./img.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./img.component";
var styles_ImgComponent = [i0.styles];
var RenderType_ImgComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ImgComponent, data: {} });
export { RenderType_ImgComponent as RenderType_ImgComponent };
export function View_ImgComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { img: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["img", 1]], null, 4, "img", [["class", "moi-image"]], [[8, "src", 4], [8, "alt", 0]], [[null, "load"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("load" === en)) {
        var pd_0 = (_co.onImageLoad() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "moi-image--is-loading": 0 }), i1.ɵdid(4, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(5, { "width": 0, "height": 1 })], function (_ck, _v) { var _co = _v.component; var currVal_2 = "moi-image"; var currVal_3 = _ck(_v, 3, 0, _co.loading); _ck(_v, 2, 0, currVal_2, currVal_3); var currVal_4 = _ck(_v, 5, 0, _co.w, _co.h); _ck(_v, 4, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.src; var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.alt, ""); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_ImgComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "moi-img", [["class", "moi-image-container"]], [[2, "loading", null], [4, "width", null], [4, "height", null], [4, "background", null]], null, null, View_ImgComponent_0, RenderType_ImgComponent)), i1.ɵdid(1, 638976, null, 0, i3.ImgComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).loading; var currVal_1 = i1.ɵnov(_v, 1).w; var currVal_2 = i1.ɵnov(_v, 1).h; var currVal_3 = i1.ɵnov(_v, 1).bgColor; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
var ImgComponentNgFactory = i1.ɵccf("moi-img", i3.ImgComponent, View_ImgComponent_Host_0, { class: "class", bgColor: "bgColor", w: "w", h: "h", src: "src", alt: "alt" }, {}, []);
export { ImgComponentNgFactory as ImgComponentNgFactory };
