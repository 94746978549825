import { Subject } from "rxjs";
import * as i0 from "@angular/core";
var DataService = /** @class */ (function () {
    function DataService() {
        this.maps = new Subject();
        this.map = new Subject();
        this.timeline = new Subject();
    }
    DataService.ngInjectableDef = i0.defineInjectable({ factory: function DataService_Factory() { return new DataService(); }, token: DataService, providedIn: "root" });
    return DataService;
}());
export { DataService };
