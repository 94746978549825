import { Component, OnInit, ViewChildren, QueryList, ElementRef } from "@angular/core";
import { DataService } from "../../services/data.service";
// import Glide from "@glidejs/glide";
import VanillaTilt from "vanilla-tilt";
import { FirebaseService } from "../../services/firebase.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"]
})
export class HomeComponent implements OnInit {
  constructor(private data: DataService, private firebaseService: FirebaseService) {}

  slideWidth: number = 260;
  slideTrack: number;
  perView: number;
  peekAfter: number;

  @ViewChildren("mapCards")
  set mapCards(mapCards: QueryList<ElementRef>) {
    if (this.isMobile()) {
      return;
    }
    this.initTilt();
  }

  _mapsSubscription: any;
  maps: Array<Object> = null;

  ngOnInit() {
    this._mapsSubscription = this.data.maps.subscribe((res: Array<Object>) => {
      this.maps = res;
    });
    this.firebaseService.getMaps();

    window.addEventListener("resize", () => this.initDestroyTilt());
  }

  ngOnDestroy(): void {
    this._mapsSubscription.unsubscribe();
    window.removeEventListener("resize", this.initDestroyTilt);
  }

  // calculateSliderSettings() {
  //   this.slideTrack = document.querySelector(".glide__track").getBoundingClientRect().width;
  //   this.perView = Math.floor(this.slideTrack / this.slideWidth);
  //   this.peekAfter = this.slideTrack - this.perView * (this.slideWidth + 8);
  // }

  // initializeSlider() {
  //   const slider = new Glide(".glide", {
  //     type: "slider",
  //     startAt: 0,
  //     bound: true,
  //     perView: this.perView,
  //     gap: 8,
  //     peek: {
  //       before: 0,
  //       after: this.peekAfter
  //     }
  //   });

  // slider.mount();
  // }
  isMobile = () => window.innerWidth <= 768;

  initDestroyTilt() {
    if (this.isMobile()) {
      this.destroyTilt();
    } else {
      this.initTilt();
    }
  }

  initTilt() {
    const cards: any = document.querySelectorAll(".card.card--with-tilt");
    cards.forEach(card => {
      VanillaTilt.init(card, {
        max: 10,
        speed: 400,
        perspective: 1000,
        reverse: true,
        transition: true
      });
    });
  }

  destroyTilt() {
    const cards: any = document.querySelectorAll(".card.card--with-tilt");
    cards.forEach(card => {
      if (card.vanillaTilt) {
        card.vanillaTilt.destroy();
      }
    });
  }
}
