import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { AppRoutingModule } from "./app-routing.module";

import { AppComponent } from "./app.component";
import { HomeComponent } from "./pages/home/home.component";
import { TimelineComponent } from "./pages/timeline/timeline.component";
import { CardComponent } from "./components/card/card.component";
import { TopbarComponent } from "./components/topbar/topbar.component";
import { ButtonComponent } from "./components/button/button.component";

// Components
import { MoiComponents } from "./components";
import { ImgComponent } from "./components/img/img.component";
import { FooterComponent } from "./components/footer/footer.component";
import { MoiButtonDirective } from "./directives/moi-button/moi-button.directive";

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    TimelineComponent,
    CardComponent,
    TopbarComponent,
    ButtonComponent,
    ImgComponent,
    FooterComponent,
    MoiButtonDirective
  ],
  imports: [BrowserModule, AppRoutingModule, MoiComponents, HttpClientModule],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
