
    <img 
      [src]="src"
      alt="{{ alt }}"
      [ngStyle]="{'width': w, 'height': h }"
      class="moi-image" 
      (load)="onImageLoad()"
      [ngClass]="{'moi-image--is-loading': loading}"
      #img 
    >
  