import { Component, OnInit, Input } from "@angular/core";
import { Router } from "@angular/router";
import { transformTitleToSlug } from "./../../utilities";
import { Map } from "../../services/data.service";

@Component({
  selector: "moi-card",
  templateUrl: "./card.component.html",
  styleUrls: ["./card.component.scss"]
})
export class CardComponent implements OnInit {
  constructor(private router: Router) {}

  @Input()
  map: Map;
  @Input()
  withTilt: boolean;

  ngOnInit() {}

  goToTimeline() {
    this.router.navigate(["/timeline/" + this.map.id + "/" + transformTitleToSlug(this.map.title)]);
  }
}
