import { DataService } from './data.service';
import * as firebase from "firebase/app";
import "firebase/firestore";
import * as i0 from "@angular/core";
import * as i1 from "./data.service";
var FirebaseService = /** @class */ (function () {
    function FirebaseService(data) {
        this.data = data;
        this.initFirebase();
    }
    FirebaseService.prototype.initFirebase = function () {
        if (firebase.apps.length)
            return;
        var config = {
            // apiKey: "AIzaSyC77IMSzncR28nKqg4uZaeZGSO4ClzX_Ps",
            // authDomain: "holy-kalo.firebaseapp.com",
            // databaseURL: "https://holy-kalo.firebaseio.com",
            projectId: "holy-moi"
            // storageBucket: "holy-kalo.appspot.com",
            // messagingSenderId: "90507705263"
        };
        firebase.initializeApp(config);
        this.db = firebase.firestore();
        var settings = { timestampsInSnapshots: true };
        this.db.settings(settings);
    };
    ;
    FirebaseService.prototype.getMaps = function () {
        var _this = this;
        this.db &&
            this.db
                .collection("maps")
                .get()
                .then(function (querySnapshot) {
                var list = [];
                querySnapshot.forEach(function (element) {
                    var data = element.data();
                    list.push(data);
                });
                _this.data.maps.next(list);
            });
    };
    ;
    FirebaseService.prototype.getMap = function (map_id) {
        var _this = this;
        this.db &&
            this.db
                .collection("maps")
                .where("id", "==", map_id)
                .get()
                .then(function (querySnapshot) {
                querySnapshot.forEach(function (element) {
                    var data = element.data();
                    _this.data.map.next(data);
                });
            });
    };
    ;
    FirebaseService.prototype.getTimeline = function (map_id) {
        var _this = this;
        this.db &&
            this.db
                .collection("timelines")
                .where("map_id", "array-contains", map_id)
                .get()
                .then(function (querySnapshot) {
                var timeline = [];
                querySnapshot.forEach(function (element) {
                    var data = element.data();
                    timeline.push(data);
                });
                timeline.sort(function (a, b) {
                    return _this.formatDateIncludingBC(a.start_date).getFullYear() - _this.formatDateIncludingBC(b.start_date).getFullYear();
                });
                _this.data.timeline.next(timeline);
            });
    };
    FirebaseService.prototype.formatDateIncludingBC = function (date) {
        if (!date) {
            throw new Error("formatDateIncludingBC error. No date provided.");
        }
        ;
        var dateFragments = date.split('-');
        if (dateFragments[0].indexOf('−') === 0 || dateFragments[0].indexOf('-') === 0) {
            var d = new Date();
            var year = Number(dateFragments[0].slice(1));
            d.setFullYear(year * -1);
            return d;
        }
        else {
            // console.log(new Date(date))
            return new Date(date);
        }
        ;
    };
    ;
    FirebaseService.ngInjectableDef = i0.defineInjectable({ factory: function FirebaseService_Factory() { return new FirebaseService(i0.inject(i1.DataService)); }, token: FirebaseService, providedIn: "root" });
    return FirebaseService;
}());
export { FirebaseService };
