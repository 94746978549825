import { Component, OnInit, OnDestroy, ViewChild, ElementRef, ViewChildren, QueryList, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { transformTitleToSlug } from "../../utilities";
// import { TweenLite } from "gsap/all";
import { DataService, Map, Moment } from "../../services/data.service";
import { FirebaseService } from "../../services/firebase.service";
import MarkerClusterer from "@google/markerclusterer";
import ClipboardJS from "clipboard";
// import { ConsoleReporter } from "jasmine";

declare var TweenLite;

declare const google: any;
declare const require;

const mapStyles = require("./../../../data/map-style.json");

interface LatLng {
  lat: string | number;
  lng: string | number;
}

@Component({
  selector: "app-timeline",
  templateUrl: "./timeline.component.html",
  styleUrls: ["./timeline.component.scss"],
  encapsulation: ViewEncapsulation.None
})

// char limit will be 360 characters
export class TimelineComponent implements OnInit, OnDestroy {
  @ViewChild("momentDialog")
  tooltip: ElementRef;

  constructor(private route: ActivatedRoute, private router: Router, private dataService: DataService, private firebaseService: FirebaseService) {}

  /**
   *
   * VARIABLES
   *
   */

  selectedMoment: Moment = null;
  googleMap: any;
  moiMap: Map;
  timeline: Array<Moment>;
  marker: any;
  selectedLatLng: LatLng = null;
  _paramSub: any;
  _queryParamSub: any;
  id: string;
  momentId: string;
  currentMomentIndex: number = 0;
  topMargin: number = 0;
  noNext: boolean = false;
  noPrev: boolean = false;
  allMoments: any;
  tooltipImageLoaded: boolean = false;

  @ViewChild("sidebar")
  sidebar: ElementRef;
  sidebarScrollTop: number = 0;

  @ViewChild("advTop")
  advTop: ElementRef;

  @ViewChildren("momentScroller") momentScroller: ElementRef;

  @ViewChildren("moments")
  moments: QueryList<ElementRef>;

  @ViewChild(".map__share > span")
  shareText: ElementRef;

  @ViewChild(".clipboard")
  clipboardElement: ElementRef;

  clipboard: any = new ClipboardJS(".map__share", {
    text: () => window.location.href
  });

  mapIconSelected = {
    path: "M0,11a11,11 0 1,0 22,0a11,11 0 1,0 -22,0",
    strokeColor: "#88ED9E",
    fillColor: "#88ED9E",
    strokeWeight: 16,
    scale: 0.1
  };

  mapIcon = {
    path: "M0,11a11,11 0 1,0 22,0a11,11 0 1,0 -22,0",
    strokeColor: "#8c8c8c",
    strokeWeight: 12,
    scale: 0.1
  };

  ngOnInit(): void {
    setTimeout(() => {
      this.initGoogleMap();
      this.paramSubscription();
      this.queryParamSubscription();
      this.dataServiceSubscription();
    }, 100); // "google is not defined" Cheap Fix

    this.tooltipImageLoaded && this.showToolTip();
  }

  /**
   *
   * MAP STUFF
   *
   */

  initGoogleMap() {
    this.googleMap = new google.maps.Map(document.getElementById("map"), {
      center: { lat: 36.7539119843, lng: 117.025756564 },
      zoom: this.moiMap && this.moiMap.zoom_factor && this.moiMap.zoom_factor > 2 ? this.moiMap.zoom_factor : 3,
      styles: mapStyles,
      disableDefaultUI: true,
      enableZoom: true,
      zoomControl: false
    });

    this.addMapListeners();
  }

  setMapCenter() {
    let { lat, lng } = this.selectedMoment;
    lat = Number(lat);
    lng = Number(lng);
    this.googleMap.setCenter({ lat, lng });
    if (!this.isMobile()) {
      this.googleMap.panBy(-135, 212);
    }
    this.showMarker({ lat, lng });
  }

  showMarker(location: LatLng) {
    this.marker && this.marker.setMap(null);
    this.marker = new google.maps.Marker({
      position: location,
      map: this.googleMap, // assign marker to map
      icon: this.mapIconSelected
    });

    !this.isMobile() && this.setTooltipPosition();

    // MARKER LISTENER
    this.marker.addListener("click", () => this.showToolTip());
  }

  addMapListeners() {
    const zoomInButton: any = document.querySelector(".map-zoom__in");
    const zoomOutButton: any = document.querySelector(".map-zoom__out");

    // ON CHANGES LISTENERS
    this.googleMap.addListener("bounds_changed", () => {
      const nw = new google.maps.LatLng(
        this.googleMap
          .getBounds()
          .getNorthEast()
          .lat(),
        this.googleMap
          .getBounds()
          .getSouthWest()
          .lng()
      );
      !this.isMobile() && this.setTooltipPosition(nw);
    });
    this.googleMap.addListener("center_changed", () => {
      !this.isMobile() && this.setTooltipPosition();
    });
    this.googleMap.addListener("zoom_changed", () => {
      !this.isMobile() && this.setTooltipPosition();
    });
    window.addEventListener("resize", () => {
      !this.isMobile() && this.setTooltipPosition();
      // Calculate topMargin
      this.topMargin = parseFloat(getComputedStyle(this.sidebar.nativeElement).paddingTop);
    });

    // CUSTOM BUTTONS LISTENERS
    zoomInButton.addEventListener("click", () => {
      if (this.googleMap.getZoom() === 15) {
        return;
      }
      this.googleMap.setZoom(this.googleMap.getZoom() + 1);
    });
    zoomOutButton.addEventListener("click", () => {
      if (this.googleMap.getZoom() === 3) {
        return;
      }
      this.googleMap.setZoom(this.googleMap.getZoom() - 1);
    });
  }

  _timelineSub: any;
  _mapSub: any;
  dataServiceSubscription() {
    this._timelineSub = this.dataService.timeline.subscribe((res: Array<Moment>) => {
      this.timeline = res;
      this.initMarkers();
      if (this.momentId) {
        const moment = this.timeline.filter((moment: Moment): boolean => moment.id == this.momentId)[0];
        const momentIndex = this.timeline.findIndex(moment => moment.id == this.momentId);
        this.currentMomentIndex = momentIndex;
        moment && this.showMoment(moment);
        this.setMapCenter();
      } else {
        const moment = this.timeline[0];
        if (moment) {
          setTimeout(() => {
            this.showMoment(moment);
            this.setMapCenter();
          }, 500);
        }
      }
    });
    this._mapSub = this.dataService.map.subscribe((res: Map) => {
      this.moiMap = res;
      const zoom_factor = res.zoom_factor && res.zoom_factor > 2 ? res.zoom_factor : 3;
      this.googleMap.setZoom(zoom_factor);
    });
  }

  paramSubscription() {
    this._paramSub = this.route.params.subscribe((params: any) => {
      this.id = params.id;
      this.firebaseService.getTimeline(this.id);
      this.firebaseService.getMap(this.id);
      this.googleMap && this.moiMap && this.moiMap.zoom_factor && this.googleMap.setZoom(this.moiMap.zoom_factor);
      this.initSidebar();
    });
  }

  queryParamSubscription() {
    this._queryParamSub = this.route.queryParams.subscribe((queryParams: any) => {
      this.momentId = queryParams.momentId;
    });
  }

  ngOnDestroy(): void {
    this._paramSub && this._paramSub.unsubscribe();
    this._queryParamSub && this._queryParamSub.unsubscribe();
    this._timelineSub && this._timelineSub.unsubscribe();
  }

  markers: any = [];

  initMarkers() {
    this.timeline.forEach((item: Moment, i: number) => {
      const marker = new google.maps.Marker({
        position: { lat: Number(item.lat), lng: Number(item.lng) },
        map: this.googleMap, // assign marker to map
        icon: this.mapIcon
      });
      marker.addListener("click", () => {
        this.currentMomentIndex = i;
        this.resetMarkerIcons();
        this.allMoments[this.currentMomentIndex].nativeElement.click();
        marker.setIcon(this.mapIconSelected);
      });
      this.markers.push(marker);
    });
    const markerCluster = new MarkerClusterer(this.googleMap, this.markers);
    markerCluster.setStyles([
      {
        url: "../../assets/images/map-clusters/35.png",
        width: 35,
        height: 35
      },
      {
        url: "../../assets/images/map-clusters/45.png",
        width: 45,
        height: 45
      },
      {
        url: "../../assets/images/map-clusters/55.png",
        width: 55,
        height: 55
      }
    ]);
    // console.log(markerCluster.getStyles());
  }

  resetMarkerIcons() {
    for (let marker of this.markers) {
      marker.setIcon(this.mapIcon);
    }
  }

  /**
   *
   * SIDEBAR
   *
   */

  sidebarScrollTimeout: any = null;

  initSidebar() {
    // Calculate topMargin
    this.topMargin = parseFloat(getComputedStyle(this.sidebar.nativeElement).paddingTop);

    // Get the updated moments
    this.moments.changes.subscribe(res => {
      if (!res.last) return;

      // Assign all moments elementRef to a new variable
      this.allMoments = this.moments.toArray();
      // Scroll to selected moment on page load
      // console.log(this.allMoments[this.currentMomentIndex].nativeElement);
      this.currentMomentIndex > -1 && this.allMoments[this.currentMomentIndex].nativeElement.click();

      // Add margin to the last moment in order to allow scroll to top and become active
      res.last.nativeElement.style.marginBottom = window.innerHeight - res.last.nativeElement.clientHeight - this.topMargin + "px";

      // Add height into moment-scroller for safari
      // let moment = res.first.nativeElement;
      // let momentHeight = moment.getBoundingClientRect().height + parseFloat(getComputedStyle(moment).marginBottom);
      // let momentLastMarginBottom = parseFloat(getComputedStyle(res.last.nativeElement).marginBottom);

      // let momentContainerHeight = momentHeight * this.allMoments.length + momentLastMarginBottom;
      // document.querySelector(".moment-scroller").setAttribute("style", `min-height: ${momentContainerHeight}px; transform: translateY(${this.topMargin}px);`);
      if (this.isSafari()) {
        let lastMoment = res.last.nativeElement;
        // let lastMomentOffsetTop = lastMoment.offsetTop;
        let lastMomentOffsetTop = lastMoment.getBoundingClientRect().top;
        let lastMomentHeight = lastMoment.getBoundingClientRect().height;
        console.log(lastMomentOffsetTop);
        const safariStyle = document.createElement("style");

        safariStyle.innerHTML = `
          .page--timeline .sidebar .moment:last-child:before {
            height: ${lastMomentOffsetTop + lastMomentHeight + 8}px
          }
        `;
        document.head.appendChild(safariStyle);
      }
    });

    this.sidebar.nativeElement.addEventListener("scroll", () => {
      this.sidebarScrollTimeout = null;
      this.sidebarScrollTimeout = setTimeout(() => {
        this.sidebarScrollTop = this.sidebar.nativeElement.scrollTop;
        const topMomentIndex = this.getTopMomentIndex();
        if (this.currentMomentIndex !== topMomentIndex) {
          this.currentMomentIndex = topMomentIndex;
          // this.allMoments[this.currentMomentIndex].nativeElement.click();
          this.showMoment(this.timeline[topMomentIndex]);
          this.setMapCenter();
        }
      }, 500);
    });

    // Player Listeners
    window.addEventListener("keydown", e => {
      switch (e.keyCode) {
        case 37:
          return this.showPreviousMoment();
        case 38:
          return this.showPreviousMoment();
        case 39:
          return this.showNextMoment();
        case 40:
          return this.showNextMoment();
        case 82:
          return this.showFirstMoment();
        default:
          break;
      }
    });
  }

  isSafari() {
    return "webkitAspectRatio" in document.documentElement.style;
  }

  /**
   *
   * MOMENTS
   *
   */

  setCurrentMomentIndex() {
    for (let index = 0; index < this.moments.length; index++) {
      if (this.timeline[index].id === this.selectedMoment.id) {
        this.currentMomentIndex = index;
        break;
      }
    }
    this.noMore();
  }

  showMoment(moment: Moment): void {
    if (JSON.stringify(moment) === JSON.stringify(this.selectedMoment)) return;
    this.selectedMoment = moment;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { momentId: moment.id, moment: transformTitleToSlug(moment.title) }
    });
  }

  getTopMomentIndex(): number {
    // Returns the top moment div of the sidebar, depending on the div's top position while scrolling inside the sidebar.
    let topMoment = 0;
    this.moments.forEach((element: ElementRef, i: number) => {
      const { top } = element.nativeElement.getBoundingClientRect();
      if (top <= this.topMargin) {
        topMoment = i;
      }
    });
    return topMoment;
  }

  onMomentClick(moment: Moment, e: any): void {
    this.hideToolTip();
    this.scrollToMoment(e, moment);
  }

  shareClick() {
    console.log(this.clipboardElement);
    this.clipboardElement.nativeElement.addClass("clipboard--visible");
    this.clipboard.on("success", (e: any) => {
      // console.log("action:", e.action);
      // console.log("text:", e.text);
      // console.log("trigger:", e.trigger);
      this.clipboardElement.nativeElement.addClass("clipboard--visible");
    });
    setTimeout(() => {
      this.clipboardElement.nativeElement.removeClass("clipboard--visible");
    }, 3000);

    this.clipboard.on("error", (e: any) => {
      console.error("Action:", e.action);
      console.error("Trigger:", e.trigger);
    });
  }

  scrollToMoment(e: any, moment?: Moment) {
    const timelineSidebar = this.sidebar.nativeElement;
    const timelinePointer = document.querySelector(".timeline-indicator__pointer");
    const currentElement = e.target;
    const timeToScroll = 0.5;

    // Position to scroll = targeted element's offsetTop - pointer's position from top
    const positionToScroll = currentElement.offsetTop - timelinePointer.getBoundingClientRect().top + 10;

    TweenLite.to(timelineSidebar, timeToScroll, {
      scrollTo: { y: positionToScroll },
      onComplete: () => this.onScrollComplete(moment)
    });
  }

  onScrollComplete(moment: Moment) {
    this.setCurrentMomentIndex();
    this.setMapCenter();
    this.showMoment(moment);
  }

  /*----------  MOMENT PLAYER ACTIONS  ----------*/

  noMore() {
    if (this.currentMomentIndex <= 0) {
      this.noPrev = true;
    } else if (this.currentMomentIndex >= this.moments.length - 1) {
      this.noNext = true;
    } else {
      this.noNext = false;
      this.noPrev = false;
    }
  }

  showNextMoment() {
    if (this.currentMomentIndex < this.moments.length - 1) {
      this.allMoments[this.currentMomentIndex + 1].nativeElement.click();
      // console.log(this.allMoments[this.currentMomentIndex + 1]);
    }
  }

  showPreviousMoment() {
    if (this.currentMomentIndex > 0) {
      this.allMoments[this.currentMomentIndex - 1].nativeElement.click();
    }
  }

  showFirstMoment() {
    this.moments.first.nativeElement.click();
  }

  /**
   *
   * TOOLTIP
   *
   */

  setTooltipPosition(nw?: object) {
    if (!this.googleMap || !this.tooltip || !this.marker || !nw) return;

    // Get marker position
    var scale = Math.pow(2, this.googleMap.getZoom());

    var worldCoordinateNW = this.googleMap.getProjection().fromLatLngToPoint(nw);
    var worldCoordinate = this.googleMap.getProjection().fromLatLngToPoint(this.marker.getPosition());

    var pixelOffset = new google.maps.Point(
      Math.floor((worldCoordinate.x - worldCoordinateNW.x) * scale),
      Math.floor((worldCoordinate.y - worldCoordinateNW.y) * scale)
    );

    this.tooltip.nativeElement.style.transform = `translateY(${pixelOffset.y}px) translateX(${pixelOffset.x - 16 - this.tooltip.nativeElement.clientWidth}px)`;

    setTimeout(() => {
      this.showToolTip();
    }, 100);
  }

  showToolTip() {
    this.tooltip.nativeElement.style.opacity = 1;
  }

  hideToolTip() {
    this.tooltip.nativeElement.style.opacity = 0;
  }

  onImageLoad(event: any) {
    this.tooltipImageLoaded = event;
  }

  isMobile() {
    return window.innerWidth <= 768;
  }
}
