<article [ngClass]="{'card': true, 'card--with-tilt': withTilt}">
  <!-- <a [routerLink]="[ '/timeline/' + convertTitleToSlug(map.title) ]"> -->
  <div class="card__body" (click)="goToTimeline()">
    <ng-container *ngIf="map.cover_img && map.cover_img.length > 1; then multipleImages; else singleImage"></ng-container>
    <ng-template #multipleImages>
      <div class="card__images-placeholder">
        <!-- <moi-img *ngFor="let image of map.cover_img; let i=index" src="{{ image.url }}" class="card__image"></moi-img> -->
        <img *ngFor="let image of map.cover_img; let i=index" src="{{ image.url }}" class="card__image" />
      </div>
    </ng-template>
    <ng-template #singleImage>
      <moi-img src="{{ map.cover_img[0].url }}" class="card__image"></moi-img>
    </ng-template>
    <!-- <img src="{{ map.coverImg }}" class="card__image" /> -->
    <h3 class="card__title ellipsis">
      {{ map.title }}
    </h3>
    <p *ngIf="map.timelines" class="card__meta">{{map.timelines.length}} moments • {{ map.timeline_title }}</p>
  </div>
  <!-- </a> -->
</article>