import { Injectable } from '@angular/core';
import { Subject } from "rxjs";

export interface Photo {
  id: string;
  url: string;
}

export interface Moment {
  id: string;
  title: string;
  slug: string;
  description: string;
  lat: string | number,
  lng: string | number,
  photos: Photo[];
}

export interface Attachment {
  id: string;
  filename: string;
  size: number;
  thumbnails: {
    small: AttachmentThumbnail,
    large: AttachmentThumbnail,
    full: AttachmentThumbnail,
  };
  type: string;
  url: string;
}

interface AttachmentThumbnail {

}

export interface Map {
  id: string;
  title: string;
  cover_img: Array<Attachment>;
  timelines: Array<string>;
  zoom_factor: number;
  timeline_title: string;
}

@Injectable({
  providedIn: 'root'
})

export class DataService {

  constructor() {

  }

  maps = new Subject();
  map = new Subject();
  timeline = new Subject();

}
