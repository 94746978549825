/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./card.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../img/img.component.ngfactory";
import * as i4 from "../img/img.component";
import * as i5 from "./card.component";
import * as i6 from "@angular/router";
var styles_CardComponent = [i0.styles];
var RenderType_CardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CardComponent, data: {} });
export { RenderType_CardComponent as RenderType_CardComponent };
function View_CardComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_CardComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "card__image"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.url, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_CardComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "card__images-placeholder"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CardComponent_3)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.map.cover_img; _ck(_v, 2, 0, currVal_0); }, null); }
function View_CardComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "moi-img", [["class", "card__image moi-image-container"]], [[2, "loading", null], [4, "width", null], [4, "height", null], [4, "background", null]], null, null, i3.View_ImgComponent_0, i3.RenderType_ImgComponent)), i1.ɵdid(1, 638976, null, 0, i4.ImgComponent, [], { class: [0, "class"], src: [1, "src"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_4 = "card__image"; var currVal_5 = i1.ɵinlineInterpolate(1, "", _co.map.cover_img[0].url, ""); _ck(_v, 1, 0, currVal_4, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).loading; var currVal_1 = i1.ɵnov(_v, 1).w; var currVal_2 = i1.ɵnov(_v, 1).h; var currVal_3 = i1.ɵnov(_v, 1).bgColor; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
function View_CardComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "card__meta"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " moments \u2022 ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.map.timelines.length; var currVal_1 = _co.map.timeline_title; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_CardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "article", [], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(2, { "card": 0, "card--with-tilt": 1 }), (_l()(), i1.ɵeld(3, 0, null, null, 8, "div", [["class", "card__body"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToTimeline() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CardComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfThen: [1, "ngIfThen"], ngIfElse: [2, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["multipleImages", 2]], null, 0, null, View_CardComponent_2)), (_l()(), i1.ɵand(0, [["singleImage", 2]], null, 0, null, View_CardComponent_4)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "h3", [["class", "card__title ellipsis"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CardComponent_5)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, true, _co.withTilt); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.map.cover_img && (_co.map.cover_img.length > 1)); var currVal_2 = i1.ɵnov(_v, 6); var currVal_3 = i1.ɵnov(_v, 7); _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3); var currVal_5 = _co.map.timelines; _ck(_v, 11, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.map.title; _ck(_v, 9, 0, currVal_4); }); }
export function View_CardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "moi-card", [], null, null, null, View_CardComponent_0, RenderType_CardComponent)), i1.ɵdid(1, 114688, null, 0, i5.CardComponent, [i6.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CardComponentNgFactory = i1.ɵccf("moi-card", i5.CardComponent, View_CardComponent_Host_0, { map: "map", withTilt: "withTilt" }, {}, []);
export { CardComponentNgFactory as CardComponentNgFactory };
